/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

/****** Helpers functions *********/

// Gets the min height of a bunch of elements using a jquery selector
function getMinHeight($el){
  var min = 3000;
  $el.each(function() {
      var h = $(this).height(); 
      if( h > 0 ) {
        min = h < min ? h : min;
      }
  });
  return min;
}

// Gets the max height of a bunch of elements using a jquery selector
function getMaxHeight($el){
  var max = 0;

  $el.each(function() {
      var h = $(this).data('height'); 
      max = h > max ? h : max;
  });

  return max;
}

// Sets the height for a bunch of elements using a jquery selector
function setHeight($el, setHeight) {
  $el.height(setHeight);
}


(function($) {

  //Settings for IE
  var ie = false;
  var ieVersion = 0;
  var ua = window.navigator.userAgent;
  var msie = ua.indexOf("MSIE ");
  if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
  ie = true;
  ieVersion = parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)));
  }


  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        
        // Foundation JavaScript
        $(document).foundation(); 
        // Init the placeholder plugin - supporting the placeholder attributes in older browsers like IE9 -
        $('input, textarea').placeholder();

        //Add a class managing the Fade In animation
        setTimeout(
          function() {
            $(".fadeIn_content").addClass('visible');
          },200 );


        // Refresh Foundation Equalizer (example)
        // new Foundation.Equalizer($("#archive-loop")).applyHeight();
        // IE8 BG Img Full Screen TEST THREE-FOUR THREE-FOUR
        function ie8_bg_img() {
            $('.ie8-bg-img').each(function() {
                var new_img_width, ratio_img, ratio_wrap, wrap_h, wrap_img, wrap_w;
                wrap_w = $(this).width();
                wrap_h = $(this).height();
                wrap_img = $(this).children('img');
                ratio_wrap = wrap_w / wrap_h;
                ratio_img = wrap_img.width() / wrap_img.height();
                if (ratio_wrap < ratio_img) {
                    wrap_img.css({
                        'height': '100%',
                        'width': 'auto'
                    });
                    new_img_width = wrap_img.width();
                    wrap_img.css({
                        'margin-left': '-' + (new_img_width - wrap_w) / 2 + 'px'
                    });
                } else {
                    wrap_img.css({
                        'width': '100%',
                        'height': 'auto'
                    });
                }
            });
        }
        if ($('.ie-version-8').length > 0) {
            ie8_bg_img();

            $(window).resize(function() {
                ie8_bg_img();
            });
        }

        // Window height classes
        function section_height_classes() {
            var window_height = $(window).height();
            var nav_height = ($('header').outerHeight());
            $('.window-height').css({"height":window_height});
            $('.window-height-80').css({"height":window_height*0.8});
            $('.window-height-less-nav').css({"height": (window_height-nav_height)});
        }
        section_height_classes();
        window.addEventListener('resize', section_height_classes);


        /******
        * hide/show nav when scrolling 
        * sources: https://medium.com/@mariusc23/hide-header-on-scroll-down-show-on-scroll-up-67bbaae9a78c#.hga7quc72
        * et  http://jsfiddle.net/mariusc23/s6mLJ/31/ 
        */
        function nav_show_hide_scroll() {
          var didScroll;
          var last_scroll_top = 0;
          var delta = 5;
          var header_height = ($('header').outerHeight())+($('nav').outerHeight())+50;
          var sticky_element = $('.sticky-scroll');

          $(window).scroll(function(event){
              didScroll = true;
          });

          setInterval(function() {
              if (didScroll) {
                  hasScrolled();
                  didScroll = false;
              }
          }, 250);

          function hasScrolled() {
              var top_position = $(this).scrollTop();
              
              // Make sure they scroll more than delta
              if(Math.abs(last_scroll_top - top_position) <= delta) {
                  return;
              }
              
              // If they scrolled down and are past the navbar, add class 
              // This is necessary so you never see what is "behind" the navbar.
              if (top_position > header_height) {
                sticky_element.addClass('scrolling');
              } else {
                sticky_element.removeClass('scrolling');
              }

              if (top_position > last_scroll_top && top_position > header_height){
                  // Scroll Down
                  sticky_element.removeClass('scrolled-up').addClass('scrolled-down');
              } else {
                  // Scroll Up
                  if(top_position + $(window).height() < $(document).height()) {
                      sticky_element.removeClass('scrolled-down').addClass('scrolled-up');
                  }
              }
              last_scroll_top = top_position;
          }
        }
        nav_show_hide_scroll();


        // Off-Canvas menu functions
        function off_canvas_stuff() {
          // Prevent scroll when off-canvas menu open
          $('.exit-canvas').on('touchmove', function(e) {
              e.preventDefault();
          });


          // Fix canvas open button to top on scroll
          var off_canvas_trigger = $('#top-menu-trigger');

          $(window).scroll(function () {
              if ($(this).scrollTop() > 100) {
                off_canvas_trigger.addClass('scrolling');
              } else {
                off_canvas_trigger.removeClass('scrolling');
              }
          });
        }
        off_canvas_stuff();


        // Activate animation on hover. Don't forget to include animation-type class to your element
        // Animation Types: https://daneden.github.io/animate.css/
        function hover_animation() {
          $('.hover-animation').hover(
            function() {
              $(this).addClass('animated');
            }, function() {
              $(this).removeClass('animated');
            }
          );
        }
        hover_animation();


        /*
        // Custom File Upload Field (Gravity Forms)
        */
        // Markup (requires Gform 'file upload' field as well)
        //<div class="custom-file-uploader">
        //    <div class="file-name"><input placeholder="No File Chosen" disabled="disabled" /> <button class="clear-input">Clear</button></div>
        //    <div class="upload-btn">
        //        <label for="input_1_30">Upload</label>
        //    </div>
        //</div>
        function custom_file_upload() {
          if ($('.custom-file-uploader').length > 0){
            $('.ginput_container_fileupload input[type="file"]').on('change', function() {
                var input_id = $(this).attr('id');
                var filename = $(this).val().split('\\').pop();
                var container = $('label[for="'+input_id+'"]').closest('.custom-file-uploader');

                if (container.find('.has-name').length === 0) {
                    container.find('.file-name input').after('<div class="has-name"></div>');
                }
                container.find('.has-name').text(filename);
                container.removeClass('cleared').addClass('active');
            });

            // Clear input button, reset custom file upload
            $('.custom-file-uploader').find('.clear-input').on('click', function(e) {
                e.preventDefault();

                var container = $(this).closest('.custom-file-uploader');
                var input_id = container.find('label').attr('for');
                var file_input = $('input[id="'+input_id+'"]');
                var filename_holder = container.find('.file-name div');

                file_input.wrap('<form>').closest('form').get(0).reset();
                file_input.unwrap();
                filename_holder.text('');

                container.addClass('cleared');

                // Prevent form submission
                e.stopPropagation();
                e.preventDefault();
            });
          }
        }
        $('document').ready(function(){
            custom_file_upload();
        });


        // Video BG Resize to Cover
        function fullscreen_video_resize() {
          if ($('.fullscreen-video video').length > 0) {
            var ww = $('.fullscreen-video').width(), 
            wh = $('.fullscreen-video').height(), 
            iw = 16, ih = 9, 
            rw = wh / ww, 
            ri = ih / iw, 
            w, 
            h, 
            mLeft, 
            mTop;
            //PLAYER_MARGINTOP = 70;
            //var l = s.aVideos.length;

            if (rw > ri) {
                w = wh / ri;
                h = wh;
            } else {
                w = ww;
                h = ww * ri;
            }
            mLeft = (ww / 2) - (w / 2);
            mTop = (wh / 2) - (h / 2);

            $('.fullscreen-video video').css({
                width: w + "px",
                height: h + "px",
                'margin-left': mLeft,
                'margin-top': mTop
            });
          }
        }
        fullscreen_video_resize();
        window.addEventListener("resize", fullscreen_video_resize);


        function sliders() {
          if ($('.fade-slider').length > 0) {
            $('.fade-slider').slick({
              autoplay: true,
              autoplaySpeed: 5000,
              arrows: false,
              dots: true,
              fade: true
            });
          }

          if ($('.gallery-slider').length > 0) {
            $('.gallery-slider').slick({
              autoplay: true,
              autoplaySpeed: 5000,
              arrows: true,
              dots: false,
              fade: false
            });
          }

          if ($('.testimonial-slider').length > 0) {
            $('.testimonial-slider').slick({
              autoplay: true,
              autoplaySpeed: 8000,
              arrows: true,
              speed: 1200,
              dots: true,
              fade: false
            });
          } 
        }
        sliders();


        function contact_form() {
          // Promotion checkboxx
          if ($('.contact form .promotion').length > 0) {
            var label = $('.contact form .promo label');
            var checkbox = label.find('input[type="checkbox"]');

            if (checkbox.is(":checked")) {
              label.addClass('checked');
            }

            label.click(function(){
              if (checkbox.is(":checked")) {
                label.addClass('checked');
              } else {
                label.removeClass('checked');
              }
            });
          }
        }
        contact_form();
        

        function newsletter_form() {
          //Ajax Mailchimp Campaign Monitor Subscription
          var news_form = $('#newsform');
          if ( news_form.length > 0 ) {
              news_form.find('.submit button').bind('click', function ( event ) {
                  if ( event ){event.preventDefault();}
                  // validate_input() is a validation function I wrote, you'll have to substitute this with your own.
                  //register(news_form);
              });
          }
          function register(news_form) {
              $.ajax({
                  type: news_form.attr('method'),
                  url: news_form.attr('action'),
                  data: news_form.serialize(),
                  cache       : false,
                  dataType    : 'json',
                  contentType: "application/json; charset=utf-8",
                  error       : function(err) { alert("Could not connect to the registration server. Please try again later."); },
                  success     : function(data) {
                      if (data.result !== "success") {
                          // Something went wrong, do something to notify the user. maybe alert(data.msg);
                          alert("Error: " + data.Message);
                      } else {
                          // It worked, carry on...
                          $('#newsform').slideUp(function(){
                            $(this).prev().slideDown();
                          });
                      }
                  }
              });
          }
          /*
          $('#newsform').submit(function(e) {
            e.preventDefault();
            $.getJSON(
            this.action + "?callback=?",
            $(this).serialize(),
            function (data) {
              if (data.Status === 400) {
                alert("Error: " + data.Message);
              } else {
                $('#newsform').slideUp(function(){
                  $(this).prev().slideDown();
                });
              }
            });
          });
          */
        }
        newsletter_form();

        // Video Lightbox
        function custom_lightbox() {
            
            // Take alt caption and put it in caption
            if ($('.gallery a').length > 0) {
              $('.gallery img').each(function(){
                var global_alt = $(this).attr('alt');

                $(this).parent().attr('data-caption', global_alt);
              });
            }

            $('[rel*="ilightbox"], .gallery a').iLightBox({
                skin: 'dark',
                overlay: {  opacity: 0.85 },
                controls: {
                    fullscreen: false,
                    thumbnail: false
                },
                minScale: 0.1,
                maxScale: 1,
                //fullViewPort: 'fit',
                path: 'horizontal'
            });
        }
        custom_lightbox();

        //Membership Form
        function membership_form() {
          if ($('.form-adhesion').length > 0) {
            var morale_regulier = 'SUNHN54HVUF8A'; // 140 / an
            var morale_reduit = 'ZQ2HH2239K53Y'; // 70 / an
            var morale_premium = 'ZU6XE6CE8QSK4'; // 210 / an
            var physique_regulier = '6F94PS4BRBUGW'; // 30 / an
            var physique_reduit = 'K5GRXH4T5SW7J'; // 15 / an
            var physique_premium = 'UANC3L7EFWTVA'; // 45 / an
            var physique_mois_argent = 'YAM2XA2R5FNFC'; // 20 / mois
            var physique_mois_or = 'AMXPPYZ9QMSWN'; // 35 / mois
            var physique_mois_platine = 'V8KMAX7KKW5FL'; // 50 / mois
            var all_radios = $(".form-adhesion .group-all");
            
            // When membership type selcted, get ID
            all_radios.find("input[type=radio]").on("change", function () {
                radio_membre = all_radios.find(":radio:checked").parent().parent().attr('id');
                //alert();
                // Change submit button ID
                $(".membership-submit input[name=hosted_button_id]").val(eval(radio_membre));
                $('.membership-submit button').removeAttr("disabled");
            });

            /*
            $(".membership-submit").submit(function(){
               // Let's find the input to check
               $(this).find("input[name=hosted_button_id]").val(radio_membre);
            });*/
          }
        }
        membership_form();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Single page
    'single': {
      init: function() {
          function post_social_share() {
            if ($('.single .social-share').length > 0) {
              //Blog Post Social Sharing Icons
              $('.single a[data-provider="facebook"]').html('<i class="fa fa-facebook"></i>');
              $('.single a[data-provider="twitter"]').html('<i class="fa fa-twitter"></i>');
              $('.single a[data-provider="linkedin"]').html('<i class="fa fa-linkedin"></i>');
              $('.single a[data-provider="google_plus"]').html('<i class="fa fa-google-plus"></i>');
              $('.single a[data-provider="mail"]').html('<i class="fa fa-envelope"></i>');
            }
          }
          post_social_share();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
